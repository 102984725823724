import activity from './m-rsvp.vue';

export default activity;

export const componentRegistrationData = {
  name: 'RSVP',
  icon: 'mdi-ticket',
  description: 'RSVP to attend',
  status: true,
  styling: {
    borderColor: '#ea6764' // color of choice for your activity
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-rsvp',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        rsvpLink: '',
        eventAgenda: '',
        eventLocations: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
